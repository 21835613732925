import { BM_VALUES } from "values/bm";
import RATING from "values/rating";
import WORKLIFE from "values/worklife";

import Profile from "./Profile";

export default class Aws extends Profile<WORKLIFE> {
  categories: WORKLIFE[] = [
    WORKLIFE.WORKLOAD,
    WORKLIFE.CONTROL,
    WORKLIFE.REWARD,
    WORKLIFE.COMMUNITY,
    WORKLIFE.FAIRNESS,
    WORKLIFE.VALUES,
  ];

  values = {
    [WORKLIFE.WORKLOAD]: {
      AVERAGE: 3.0,
      STDEV: 0.8,
      RATING: [2.39, 3.5],
    },
    [WORKLIFE.CONTROL]: {
      AVERAGE: 3.3,
      STDEV: 0.9,
      RATING: [2.66, 4.0],
    },
    [WORKLIFE.REWARD]: {
      AVERAGE: 3.2,
      STDEV: 0.9,
      RATING: [2.66, 3.75],
    },
    [WORKLIFE.COMMUNITY]: {
      AVERAGE: 3.4,
      STDEV: 0.8,
      RATING: [2.74, 3.8],
    },
    [WORKLIFE.FAIRNESS]: {
      AVERAGE: 2.8,
      STDEV: 0.8,
      RATING: [2.24, 3.25],
    },
    [WORKLIFE.VALUES]: {
      AVERAGE: 3.2,
      STDEV: 0.8,
      RATING: [2.66, 3.75],
    },
  };

  getCategories(): WORKLIFE[] {
    return this.categories;
  }

  calculateRating(mean: number, category: WORKLIFE): RATING {
    const progress = this.getMeanValue(category);
    // const progress = this.calculateZProgress(mean, category);

    const [below, above] = this.getBMValues(category).RATING;

    if (progress <= below) return RATING.BAD;
    if (progress <= above) return RATING.AVERAGE;

    return RATING.GOOD;
  }

  getBMValues(category: WORKLIFE): BM_VALUES {
    return this.values[category];
  }
}
